$('.dashboard-sidebar-toggle').on('click', function() {
  $('.dashboard-sidebar').toggleClass('show')

  $(this).text($('.dashboard-sidebar').hasClass('show') ? 'Hide' : 'Menu')
})

$('.notice-box').each(function() {
  $(this).data('min-height', Math.max($(this).parent().height(), 150))
  $(this).data('max-height', $(this).get(0).scrollHeight)

  $(this).addClass('collapsed');
  $(this).outerHeight($(this).data('min-height'));
})

$('.notice-box .toggle-button').on('click', function() {
  const box = $(this).parents('.notice-box')

  if(box.hasClass('collapsed')) {
    box.removeClass('collapsed');
    box.outerHeight(box.data('max-height'))
  } else {
    box.addClass('collapsed');
    box.outerHeight(box.data('min-height'))
  }
})

var ono =
{
	endpoints: ['nat', 'gen', 'eth', 'jur', 'lng'],
	
	forget: function()
	{
		$( '#forgot-form' ).removeClass( 'd-none' );
		$( '#forget-toggle' ).empty().text( 'Forgot Password' );
		
		$( '#forgot-form' ).on( 'submit', function( e )
		{
			e.preventDefault();
			var form = $( this );
			var values = form.serializeArray();
			var data = {};
			$( values ).each( function( index, obj ) { data[obj.name] = obj.value; } );
			data.forgot = 1;
			$.ajax(
			{
				method: 'POST',
				url: 'a?c=Account&m=forgot',
				dataType: 'json',
				data: data,
				success: function( response )
				{
					$( '#forgot-status' ).empty();
					if( response.status == 'error' )
					{
						$.each( response.errors, function( i, error )
						{
							ono.appendError( 'forgot-status', error, 'mb-4' );
						});
					}
					else
					{
						ono.appendMessage( 'forgot-status', response.message, 'mb-4' );
						$( '#login-modal input' ).remove();
						$( '#login-modal button' ).remove();
						$( '#login-modal label' ).remove();
					}
				},
				error: function( err )
				{
					$( '#forgot-status' ).empty();
					ono.appendError( 'forgot-status', 'There was an unexpected error - please try again', 'mb-2' );
				}
			})
		});
	},
	
	getCookie: function( cname )
	{
		var name = cname + '=';
		var ca = document.cookie.split( ';' );
		for( var i = 0; i < ca.length; i++ )
		{
			var c = ca[i];
			while( c.charAt( 0 ) == ' ' )
				c = c.substring( 1 );
			if( c.indexOf( name ) == 0 )
				return c.substring( name.length, c.length );
		}
		return '';
	},

	initialiseAccount: function()
	{
		$( '#account' ).empty();
		if( ono.getCookie( 'u' ) != '' )
		{
			$( '#account' ).append( '<a class="nav-link" href="account">Account <svg class="icon ml-3" data-icon="profile"><use href="assets/img.svg#profile"></use></svg></a>' );
			$( '.try-free' ).remove();
		}
		else
		{
			$( '#account' ).append( '<a class="nav-link" onclick="ono.login()">Login</a>' );
			
			if( ono.getCookie( 'r' ) != '' )
			{
				$.ajax(
				{
					method: 'GET',
					url: 'a?c=Account&m=remember',
					dataType: 'json',
					success: function( response )
					{
						if( response == true )
							ono.initialiseAccount();
					}
				});
			}
		}
	},
	
	initialiseForms: function()
	{
		$( '.form-control' )
		.on( 'blur', function()
		{
			$(this).val().replace( / /g, '' ) === '' ? $( this ).addClass( 'empty' ) : 	$( this ).removeClass( 'empty' );
		})
		.on( 'change', function()
		{
			if( $( this ).is( 'select' ) && $( this ).val() !== '' && $( this ).get( 0 ).options[0].value === '' )
			{
				$( this ).get( 0 ).remove( 0 );
			}
		})
		.each( function()
		{
			const placeholder = $( this ).attr( 'data-placeholder' );
			if( placeholder )
			{
				$( this ).after( `<span>${placeholder}</span>` );
				$( this ).attr( 'data-placeholder', null );
				$( this ).attr( 'placeholder', ' ' );
			}
			$( this ).trigger( 'blur' );
		});
		
		$( '.form-control-helper' ).on( 'click', function ( e )
		{
			e.preventDefault();
			if( $( this ).hasClass( 'form-control-helper-password' ) )
			{
				const input = $( this ).parents( '.form-control-wrapper' ).find( '.form-control' );
				if( input.attr( 'type' ) === 'password' )
					input.attr( 'type', 'text' );
				else
					input.attr( 'type', 'password' );
				input.trigger( 'inputTypeChange' );
				input.focus();
			}
		});
		
		$( 'input[type=password]' ).bind( 'inputTypeChange', function ()
		{
			const passwordHelper = $( this ).parents( '.form-control-wrapper' ).find( '.form-control-helper-password' );
			if( passwordHelper.length )
			{
				if( $( this ).attr( 'type' ) === 'password' ) passwordHelper.text( 'Show' );
				else passwordHelper.text( 'Hide' );
			}
		}).trigger( 'inputTypeChange' );
	},
	
	initialiseLogin: function()
	{
		$( '.btn-submission-box-toggle' ).on( 'click', function() { $( this ).remove(); $( '.submission-box-wrapper' ).show(); });
	},
	
	initialisePage: function()
	{
		$( '.blur-toggle' ).on( 'change', function()
		{
			if( $( this ).is( ':checked' ) )
				$( this ).parents( 'section' ).find( '.blur-target' ).removeClass( 'blurred' );
			else
				$( this ).parents( 'section' ).find( '.blur-target' ).addClass( 'blurred' );
		});
		
		$( window ).on( 'scroll', function()
		{
			if( $( window ).scrollTop() > 10 )
				$( 'nav.navbar' ).addClass( 'scrolled' );
			else
				$( 'nav.navbar' ).removeClass( 'scrolled' );
		});
		$( '.collapse' ).collapse( { toggle: false } );
		
		$( 'svg.icon' ).each( function()
		{
			const use = document.createElementNS( 'http://www.w3.org/2000/svg', 'use' );
			use.setAttributeNS( 'http://www.w3.org/1999/xlink', 'href', `assets/img.svg#${$( this ).attr( 'data-icon' )}` );
			$( this ).get( 0 ).appendChild( use );
		});
		
		$( '.payment-method input' ).on( 'change', function()
		{
			if( $( this ).is( ':checked' ) )
				$( this ).parents( '.payment-method' ).addClass( 'active' );
			else
				$( this ).parents( '.payment-method' ).removeClass( 'active' );
		});
	},
	
	login: function()
	{
		ono.modalClose();
		
		$.get( 'data/tpl/login.html', function( data )
		{
			$( 'body' ).append( data );
			ono.initialiseForms();
			$( '#login-modal' ).modal( 'show' );
			
			$( '.login-form' ).on( 'submit', function( e )
			{
				e.preventDefault();
				var form = $( this );
				var values = form.serializeArray();
				var data = {};
				$( values ).each( function( index, obj ) { data[obj.name] = obj.value; } );
				data.login = 1;
				ono.loginAJAX( data, true );
			});
		});
	},
	
	loginAJAX: function( data, redirect = false )
	{
		$.ajax(
		{
			method: 'POST',
			url: 'a?c=Account&m=login',
			dataType: 'json',
			data: data,
			success: function( response )
			{
				$( '#login-status' ).empty();
				if( response.status == 'error' )
				{
					$.each( response.errors, function( i, error )
					{
						ono.appendError( 'login-status', error, 'mb-4' );
					});
				}
				else
				{
					if( redirect )
						window.location.href = 'account';
					else
					{
						ono.initialiseAccount();
						prch.validateCheckout();
					}
				}
			},
			error: function( err )
			{
				$( '#login-status' ).empty();
				ono.appendError( 'login-status', 'There was an unexpected error - please try again', 'mb-2' );
			}
		});
	},
	
	modalClose: function()
	{
		$( '.modal' ).remove();
		$( '.modal-backdrop' ).remove();
	},
	
	register: function()
	{
		ono.modalClose();
		$( '#register-errors' ).empty();
		
		$.get( 'data/tpl/register.html', function( data )
		{
			$( 'body' ).append( data );
			ono.initialiseForms();
			$( '#register-modal' ).modal( 'show' );
			
			$( '.register-form' ).on( 'submit', function( e )
			{
				e.preventDefault();
				var form = $( this );
				var values = form.serializeArray();
				var data = {};
				$( values ).each( function( index, obj ) { data[obj.name] = obj.value; } );
				data.register = 1;
				$.ajax(
				{
					method: 'POST',
					url: 'a?c=Account&m=register',
					dataType: 'json',
					data: data,
					success: function( response )
					{
						$( '#register-errors' ).empty();
						if( response.status == 'error' )
						{
							$.each( response.errors, function( i, error )
							{
								ono.appendError( 'register-errors', error, 'mb-4' );
							});
						}
						else
						{
							ono.appendMessage( 'register-errors', 'An email has been sent to <b>' + response.user.email + '</b> with a link to verify your account. If you can\'t find it in your inbox, check your spam folder for an email from no-reply@4b.rs.<br />', 'mb-2' );
							ono.appendMessage( 'register-errors', 'Your API key is: <b>' + response.user.apiKey + '</b><br />', 'mb-2' );
							ono.appendMessage( 'register-errors', 'To familiarize yourself with OnoGraph, consult the <a href="documentation">documentation</a>, which contains background information, general advice, <a href="documentation/api">API documentation</a> and a <a href="documentation/upload">guide</a> for processing CSV and Excel files.<br />', 'mb-2' );
							ono.appendMessage( 'register-errors', 'You can begin processing CSV and Excel files <a href="account/upload">here</a> and view outputs for single queries via the <a href="account/console">console</a>.<br />', 'mb-2' );
							ono.appendMessage( 'register-errors', 'If you require assistance, raise a <a href="account/support">support ticket</a>.<br />', 'mb-2' );
							ono.appendMessage( 'register-errors', 'Your free account is replenished with 100 credits each day. Most single queries cost one credit. You can purchase more credits from your <a href="account">account dashboard</a>.<br />', 'mb-2' );
							$( '.register-form' ).remove();
							ono.initialiseAccount();
						}
					},
					error: function( err )
					{
						ono.appendError( 'register-errors', 'There was an unexpected error - please try again', 'mb-2' );
					}
				})
			});
		});
	},
	
	appendError: function( id, msg, css )
	{
		$( '#' + id ).append( '<p class="alert alert-warning ' + css + ' text-center">' + msg + '</p>' );
	},
	
	appendMessage: function( id, msg, css )
	{
		$( '#' + id ).append( '<p class="alert alert-success ' + css + ' text-center">' + msg + '</p>' );
	},
	
	storageAvailable: function()
	{
		if( typeof ono.storageAvailable == 'undefined' )
		{
			try
			{
				localStorage.setItem( 'fb', true );
				localStorage.removeItem( 'fb' );
				ono.storageAvailable = true;
			}
			catch( e )
			{
				ono.storageAvailable = false;
			}
		}
		
		return ono.storageAvailable;
	}
}

var prch =
{
	selectTier: function( cost )
	{
		$( '#cost' ).val( cost ).trigger( 'change' ).focus();
	},
	
	configureIncreaseCost: function( cost, nextTier, nextTierPerQuery )
	{
		$( '#increase-cost' ).removeClass( 'd-none' );
		var diff = nextTier - cost;
		$( '#increase-cost .cost' ).text( '$' + diff );
		$( '#increase-cost .per-query' ).text( '$' + nextTierPerQuery );
		$( '#increase-cost button' ).click( function()
		{
			$( '#cost-checkout' ).val( nextTier ).trigger( 'change' );
		});
	},
	
	initialise: function()
	{
		var suffixes = ['', '-checkout'];
		$.each( suffixes , function( index, suffix )
		{ 
			$( '#cost' + suffix ).on( 'propertychange change keyup input paste', function()
			{
				var val = this.value.replace( /[^0-9]/g, '' );
				if( parseInt( val ) == val && val != '' )
				{
					if( val.length > 1 )
					{
						var multiplier = 1;
						if( val >= 50 && val < 300 )
						{
							multiplier = 2;
							prch.configureIncreaseCost( val, 300, '0.001' );
						}
						else if( val < 50 )
						{
							multiplier = 3;
							prch.configureIncreaseCost( val, 50, '0.002' );
						}
						else
							$( '#increase-cost' ).addClass( 'd-none' );
						this.value = parseInt( val ).toLocaleString();
						$( '#no-queries' + suffix ).val( Math.ceil( ( val / multiplier ) * 1000 ).toLocaleString() );
						$( '#checkout-now' ).removeAttr( 'disabled' );
					}
					else
					{
						$( '#no-queries' + suffix ).val( '-' );
						if( suffix == '-checkout' )
							$( '#checkout-now' ).attr( 'disabled', 'yes' );
					}
				}
				else
				{
					this.value = '';
					$( '#no-queries' + suffix ).val( '-' );
				}
			});
		});
	},
	
	validateCheckout: function()
	{
		$.ajax(
		{
			method: 'GET',
			url: 'a?c=Account&m=validateCheckout',
			dataType: 'json',
			success: function( response )
			{
				if( response.logged == 0 )
				{
					$( '#checkout-credentials' ).removeClass( 'd-none' );
					$( '#account-details' ).addClass( 'd-none' );
					prch.initialiseAddressStorage();
				}
				else
				{
					$( '#account-details' ).removeClass( 'd-none' );
					$( '#checkout-credentials' ).remove();
					$( '#account-email' ).text( response.data.email );
					prch.populateAddress( response.data );
				}
			}
		});
	},
	
	populateAddress: function( data )
	{
		$.each( data , function( name, value )
		{
			if( value.length > 0 )
				$( '#purchase-modal input[name ="' + name + '"]' ).val( value ).focus();
		});
	},
	
	initialiseAddressStorage: function()
	{
		if( ono.storageAvailable() )
		{
			var purchaseAddress = JSON.parse( localStorage.getItem( 'purcahse-address' ) );
			if( purchaseAddress )
				prch.populateAddress( purchaseAddress );
			window.setInterval( function()
			{
				var purchaseAddress = {};
				$.each( $( '#checkout-address input' ), function( index, el )
				{
					el = $( el );
					purchaseAddress[el.attr( 'name' )] = el.val();
				});
				localStorage.setItem( 'purcahse-address', JSON.stringify( purchaseAddress ) );
			}, 3000 );
		}
	},
	
	checkoutLogin: function()
	{
		var data = {};
		data.email = $( '#checkout-credentials input[name="email"]' ).val();
		data.password = $( '#checkout-credentials input[name="password"]' ).val();
		data.login = 1;
		ono.loginAJAX( data, false );
	},
	
	start: function()
	{
		ono.modalClose();
		$( '#cost-status' ).empty();
		var cost = $( '#cost' ).val().replace( /[^0-9]/g, '' );
		if( parseInt( cost ) != cost || cost == '' || cost < 10 )
		{
			ono.appendError( 'cost-status', 'Cost must be $10 or more', 'mb-2' );
			return false;
		}
		var noQueries = $( '#no-queries' ).val();
		
		$.get( 'data/tpl/purchase.html', function( data )
		{
			$( 'body' ).append( data );
			ono.initialiseForms();
			ono.initialisePage();
			prch.initialise();
			$( '#purchase-modal' ).modal( 'show' );
			$( '.collapse-card .collapse' ).on( 'show.bs.collapse', function() { $( this ).parents( '.collapse-card' ).addClass( 'active' ); });
			$( '.collapse-card .collapse' ).on( 'hide.bs.collapse', function() { $( this ).parents( '.collapse-card' ).removeClass( 'active' ); });
			$( '.collapse' ).collapse( { toggle: false } );
			
			window.setTimeout( function()
			{
				$( '#no-queries-checkout' ).val( noQueries ).focus();
				$( '#cost-checkout' ).val( cost ).trigger( 'change' ).focus();
				prch.validateCheckout();
				
				$( '#purchase-form' ).on( 'submit', function( e )
				{
					$( '#login-status' ).empty();
					$( '#purchase-status' ).empty();
					e.preventDefault();
					var form = $( this );
					var values = form.serializeArray();
					var data = {};
					$( values ).each( function( index, obj ) { data[obj.name] = obj.value; } );
					data.checkout = 1;
					$.ajax(
					{
						method: 'POST',
						url: 'a?c=Account&m=purchase',
						dataType: 'json',
						data: data,
						success: function( response )
						{
							if( response.status == 'error' )
							{
								$.each( response.errors, function( i, error )
								{
									ono.appendError( 'purchase-status', error, 'mb-4' );
								});
							}
							else
								prch.checkout( response );
						},
						error: function( err )
						{
							ono.appendError( 'purchase-status', 'There was an unexpected error - please try again', 'mb-2' );
						}
					});
				});
			}, 150 );
		});
	},
	
	checkout: function( config )
	{
		ono.appendMessage( 'purchase-status', config.message );
		$( '.purchase-portion' ).empty().append( '<div id="paypal-container"></div>' );
		$( '.sidebar-portion' ).remove();
		ono.initialiseAccount();
		
		paypal.Buttons(
			{
				style: { color: 'white', shape: 'pill', label: 'checkout' },
				funding: { allowed: [ paypal.FUNDING.CARD ] },
				createOrder: function( data, actions )
				{
					return actions.order.create( { intent: 'CAPTURE', purchase_units: [{ amount: { currency_code: 'USD', value: config.cost } }] } );
				},
				onApprove: function( data, actions )
				{
					return actions.order.capture().then( function( details )
					{
						$( '#paypal-container' ).remove();
						$( '#purchase-status' ).empty();
						var purchase = details.purchase_units[0].payments.captures[0];
						if( purchase.status != 'VOIDED' )
							prch.checkoutAJAX( config, purchase );
						else
							ono.appendError( 'purchase-status', 'Your payment was unsuccessful', 'mb-4' );
					});
				}
			}
		).render( '#paypal-container' );
	},
	
	checkoutAJAX: function( config, purchase )
	{
		var data = { userRef: config.user, transactionId: purchase.id, status: 'pending', cost: purchase.amount.value, newAccount: config.newAccount };
		$.ajax(
		{
			method: 'POST',
			url: 'a?c=Account&m=finalisePurchase',
			dataType: 'json',
			data: data,
			success: function( response )
			{
				if( response.status == 'error' )
					ono.appendError( 'purchase-status', response.message, 'mb-4' );
				else
					ono.appendMessage( 'purchase-status', response.message, 'mb-4' );
			},
			error: function( err )
			{
				ono.appendError( 'purchase-status', 'There was an unexpected error - please contact support, quoting the transaction ID: ' + purchase.id, 'mb-4' );
			}
		});
	}
}

var qry =
{
	start: function( loggedIn = false )
	{
		ono.modalClose();
		$( '#query-status' ).empty();
		var endpoint = $( '#query-characteristic' ).val();
		if( ono.endpoints.indexOf( endpoint ) == -1 )
		{
			ono.appendError( 'query-status', 'Select a characteristic', 'mb-2' );
			return false;
		}
		
		if( endpoint == 'nat' )
		{
			var fn = $.trim( $( '#query-forename' ).val() );
			var sn = $.trim( $( '#query-surname' ).val() );
			var ssn = $.trim( $( '#query-second-surname' ).val() );
			if( ( fn.length < 2 ) || ( fn.length > 64 ) || ( sn.length < 2 ) || ( sn.length > 64 ) )
			{
				ono.appendError( 'query-status', 'Names should be between 2 and 64 characters', 'mb-2' );
				return false;
			}
			var data = { fn: fn, sn: sn, ssn: ssn, endpoint: endpoint, loggedIn: loggedIn ? 1 : 0 };
		}
		else if( endpoint == 'gen' )
		{
			var fn = $.trim( $( '#query-forename' ).val() );
			console.log( fn );
			if( ( fn.length < 2 ) || ( fn.length > 64 ) )
			{
				ono.appendError( 'query-status', 'Forename should be between 2 and 64 characters', 'mb-2' );
				return false;
			}
			var data = { fn: fn, endpoint: endpoint, loggedIn: loggedIn ? 1 : 0 };
		}
		else if( endpoint == 'jur' )
		{
			var name = $.trim( $( '#query-name' ).val() );
			var type = $.trim( $( '#query-type' ).val() );
			if( name.length < 2 || name.length > 64 )
			{
				ono.appendError( 'query-status', 'Name should be between 2 and 64 characters', 'mb-2' );
				return false;
			}
			var data = { name: name, type: type, endpoint: endpoint, loggedIn: loggedIn ? 1 : 0 };
		}                                                                                   
		
		$.ajax(
		{
			method: 'GET',
			url: 'a?c=Query&m=query',
			data: data,
			dataType: 'json',
			success: function( response )
			{
				if( endpoint == 'nat' )
					qry.queryNat( response, loggedIn, fn, sn );
				else if( endpoint == 'gen' )
					qry.queryGen( response, loggedIn, fn );
				else if( endpoint == 'jur' )
					qry.queryJur( response, loggedIn, name, type );
			},
			error: function( response )
			{
				ono.appendError( 'query-status', 'An unexpected error occured, please try again', 'mb-2' );
			}
		});
	},
	
	queryNat: function( response, loggedIn, fn, sn )
	{
		if( typeof( response.data ) !== 'undefined' )
		{
			if( loggedIn == true )
				qry.natLoggedIn( response, fn, sn );
			else
				qry.natPublic( response, fn, sn );
		}
		else if( typeof( response.message ) !== 'undefined' )
			ono.appendError( 'query-status', response.message, 'mb-2' );
		else
			ono.appendError( 'query-status', 'The name(s) your provided are unknown', 'mb-2' );
	},
	
	queryGen: function( response, loggedIn, fn )
	{
		if( typeof( response.data ) !== 'undefined' )
		{
			if( loggedIn == true )
				qry.genLoggedIn( response, fn );
			else
				qry.genPublic( response, fn );
		}
		else if( typeof( response.message ) !== 'undefined' )
			ono.appendError( 'query-status', response.message, 'mb-2' );
		else
			ono.appendError( 'query-status', 'The name(s) your provided are unknown', 'mb-2' );
	},
	
	queryJur: function( response, loggedIn, name, type )
	{
		if( typeof( response.data ) !== 'undefined' )
		{
			if( loggedIn == true )
				qry.jurLoggedIn( response, name, type );
			else
				qry.jurPublic( response, name, type );
		}
		else if( typeof( response.message ) !== 'undefined' )
			ono.appendError( 'query-status', response.message, 'mb-2' );
		else
			ono.appendError( 'query-status', 'The name(s) your provided are unknown', 'mb-2' );
	},
	
	jurPublic: function( response, name, type )
	{
		$.get( 'data/tpl/query-jur.html', function( data )
		{
			$( 'body' ).append( data );
			$( '#query-modal' ).modal( 'show' );
			$( '#query-modal .name' ).html( name );
			$( '#query-modal .type' ).html( type );
			var json = JSON.stringify( response, null, 2 );
			document.getElementById( 'json' ).innerHTML = json;
			
			qry.jurRow( response.data );
		});
	},
	
	jurLoggedIn: function( response, fn, sn )
	{
		$( '#map' ).show();
		var jurisdictions = {};
		var highestPercent = 0;
		$.each( response.data.jurisdictions, function( index, jurisdiction )
		{
			if( jurisdiction.percent > highestPercent )
				highestPercent = jurisdiction.percent;
			jurisdictions[jurisdiction.iso] = { incidence: jurisdiction.incidence, percent: jurisdiction.percent, ratio: jurisdiction.ratio, rank: jurisdiction.rank };
		});
		cnsl.multiplier = 100 / highestPercent;
		
		$.each( cnsl.geoJSON.features, function( index, feature )
		{
			if( typeof jurisdictions[feature.properties.iso] !== 'undefined' )
			{
				feature.properties.incidence = jurisdictions[feature.properties.iso].incidence;
				feature.properties.percent = jurisdictions[feature.properties.iso].percent;
				feature.properties.ratio = jurisdictions[feature.properties.iso].ratio;
				feature.properties.rank = jurisdictions[feature.properties.iso].rank;
			}
			else
				feature.properties.percent = 0;
		});
		
		if( cnsl.layer !== false )
		cnsl.map.removeLayer( cnsl.layer );
		cnsl.layer = L.geoJson( cnsl.geoJSON,
		{
			style: cnsl.style,
			onEachFeature: cnsl.onEachFeature
		}).addTo( cnsl.map );
		
		var tpl = '<table class="table"><thead><tr><th class="pl-0">Jurisdiction</th><th>Incidence</th><th>Percent</th><th>Ratio</th><th>Rank</th></tr></thead><tbody class="jurisdictions"></tbody></table>';
		$( '#probability' ).empty().append( tpl );
		qry.jurRow( response.data );
		
		qry.updateCredits( response );
	},
	
	updateCredits: function( response )
	{
		$( '.credits' ).removeClass( 'd-none' );
		$( '.credits-used' ).text( response.creditsUsed );
		cnsl.creditsRemaining += response.creditsUsed;
		$( '.credits-session' ).text( cnsl.creditsRemaining.toLocaleString() );
		$( '.credits-remaining' ).text( response.creditsRemaining.toLocaleString() );
	},
	
	natLoggedIn: function( response, fn, sn )
	{
		$( '#map' ).show();
		var jurisdictions = {};
		var highestPercent = 0;
		$.each( response.data.countries, function( index, jurisdiction )
		{
			if( jurisdiction.percent > highestPercent )
				highestPercent = jurisdiction.percent;
			jurisdictions[jurisdiction.iso] = jurisdiction.percent;
		});
		cnsl.multiplier = 100 / highestPercent;
		
		$.each( cnsl.geoJSON.features, function( index, feature )
		{
			if( typeof jurisdictions[feature.properties.iso] !== 'undefined' )
			{
				feature.properties.percent = jurisdictions[feature.properties.iso];
			}
			else
				feature.properties.percent = 0;
		});
		
		if( cnsl.layer !== false )
		cnsl.map.removeLayer( cnsl.layer );
		cnsl.layer = L.geoJson( cnsl.geoJSON,
		{
			style: cnsl.style,
			onEachFeature: cnsl.onEachFeature
		}).addTo( cnsl.map );
		
		var tpl = '<table class="table"><thead><tr><th class="pl-0">Country/Jurisdiction</th><th>Probability</th></tr></thead><tbody class="jurisdictions"></tbody></table><table class="table"><thead><tr><th class="pl-0">Onosphere</th><th>Probability</th></tr></thead><tbody class="onospheres"></tbody></table>';
		$( '#probability' ).empty().append( tpl );
		qry.natRow( response.data );
		
		qry.updateCredits( response );
	},
	
	genLoggedIn: function( response, fn )
	{
		$( '#map' ).hide();
		var tpl = '<table class="table"><thead><tr><th class="pl-0">Country/Jurisdiction</th><th>Gender</th><th>Percent</th><th>Sample</th></tr></thead><tbody class="jurisdictions"></tbody></table>';
		$( '#probability' ).empty().append( tpl );
		qry.genRow( response.data );
		
		qry.updateCredits( response );
	},
	
	natPublic: function( response, fn, sn )
	{
		$.get( 'data/tpl/query-nat.html', function( data )
		{
			$( 'body' ).append( data );
			$( '#query-modal' ).modal( 'show' );
			$( '#query-modal .fn' ).html( fn );
			$( '#query-modal .sn' ).html( sn );
			var json = JSON.stringify( response, null, 2 );
			document.getElementById( 'json' ).innerHTML = json;
			
			qry.natRow( response.data );
		});
	},
	
	genPublic: function( response, fn )
	{
		$.get( 'data/tpl/query-gen.html', function( data )
		{
			$( 'body' ).append( data );
			$( '#query-modal' ).modal( 'show' );
			$( '#query-modal .fn' ).html( fn );
			var json = JSON.stringify( response, null, 2 );
			document.getElementById( 'json' ).innerHTML = json;
			
			qry.genRow( response.data );
		});
	},
	
	natRow: function( data )
	{
		$.each( data.countries, function( i, jur )
		{
			$( '.jurisdictions' ).append( '<tr><td><svg class="flag"><use href="/assets/img.svg#' + jur.iso + '"></use></svg> ' + jur.jurisdiction + '</td><td>' + jur.percent + '</td></tr>' );
		});
		$.each( data.spheres, function( i, jur )
		{
			$( '.onospheres' ).append( '<tr><td>' + jur.sphere + '</td><td>' + jur.percent + '</td></tr>' );
		});
	},
	
	genRow: function( data )
	{
		if( typeof( data.world ) !== 'undefined' )
		{
			$( '.jurisdictions' ).append( '<tr><td>World</td><td>' + data.world.gender + '</td><td>' + data.world.percent + '</td><td>' + data.world.sample.toLocaleString() + '</td></tr>' );
		}
		$.each( data.jurisdictions, function( i, jur )
		{
			$( '.jurisdictions' ).append( '<tr><td><svg class="flag"><use href="/assets/img.svg#' + jur.iso + '"></use></svg> ' + jur.jurisdiction + '</td><td>' + jur.gender + '</td><td>' + jur.percent + '</td><td>' + jur.sample.toLocaleString() + '</td></tr>' );
		});
	},
	
	jurRow: function( data )
	{
		if( typeof( data.world ) !== 'undefined' )
		{
			$( '.jurisdictions' ).append( '<tr><td>World</td><td>' + data.world.incidence.toLocaleString() + '</td><td>' + data.world.percent + '</td><td>' + data.world.ratio.toLocaleString() + '</td><td>' + data.world.rank.toLocaleString() + '</td></tr>' );
		}
		$.each( data.jurisdictions, function( i, jur )
		{
			$( '.jurisdictions' ).append( '<tr><td><svg class="flag"><use href="/assets/img.svg#' + jur.iso + '"></use></svg> ' + jur.jurisdiction + '</td><td>' + jur.incidence.toLocaleString() + '</td><td>' + jur.percent + '</td><td>1:' + jur.ratio.toLocaleString() + '</td><td>' + jur.rank.toLocaleString() + '</td></tr>' );
		});
	},
	
	change: function( el, evt )
	{
		var val = $( el ).val();
		$( '.switch-control' ).remove();
		if( val == 'gen' )
			$( '.switch-controls' ).prepend( '<div class="col-lg-6 switch-control"><div class="form-control-wrapper dark"><input id="query-forename" class="form-control" data-placeholder="Forename" /></div></div>' );
		if( val == 'jur' )
			$( '.switch-controls' ).prepend( '<div class="col-lg-3 switch-control"><div class="form-control-wrapper dark"><input id="query-name" class="form-control" data-placeholder="Name" /></div></div><div class="col-lg-3 switch-control"><div class="form-control-wrapper form-control-wrapper-select text-white dark"><select id="query-type" class="form-control" data-placeholder="Select Name Type"><option value=""></option><option value="forename">Forename</option><option value="surname">Surname</option></select></div></div>' );
		else if( val == 'nat' )
			$( '.switch-controls' ).prepend( '<div class="col-lg-3 switch-control"><div class="form-control-wrapper dark"><input id="query-forename" class="form-control" data-placeholder="Forename" /></div></div><div class="col-lg-3 switch-control"><div class="form-control-wrapper dark"><input id="query-surname" class="form-control" data-placeholder="Surname" /></div></div>' );
		ono.initialiseForms();
	}
}

ono.initialiseAccount();
ono.initialiseForms();
ono.initialisePage();
prch.initialise();